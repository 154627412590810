.dropdown-toggle::after {
  display: none !important;
}

.grey-background-color {
  background-color: #f7f7f7;
}

.light-blue-background-color {
  background-color: #0d6dfd25;
}

.loading-indicator {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100vh;
  margin: 0;
}

.loading {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 999;
  width: 100vw;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.24);
}
